import { defineStore } from 'pinia';
import axios from 'axios';
import { PeriodResponse } from '@/models/SetUpResopnse';
import config from '../../config';

export const useUpLoadSetUpStore = defineStore('uploadSetUp', {
  state: () => ({
    periodDetail: null as PeriodResponse | null,
    isLoading: true,
    error: null,
  }),
  actions: {
    async fetchUpLoadSetUpDetail() {
      this.isLoading = true;
      this.error = null;
      const accessToken = sessionStorage.getItem('accessToken');
      const envConfig = config();
      try {
        const response = await axios.get(
          `${(envConfig.UWSApi as any).host}/api/Setup`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        console.log('response_period::>', response?.data?.data);
        this.periodDetail = response?.data?.data || null; // จัดเก็บข้อมูลที่ได้จาก API
      } catch (error) {
        console.log('error_period::>', error);
        error = error; // จัดการกับข้อผิดพลาด
      } finally {
        this.isLoading = false;
      }
    },
  },
});
