<template>
  <v-app v-if="!isLoading">
    <v-navigation-drawer
      app
      v-model="drawer"
      :rail="rail"
      class="drawer-container"
      permanent
      style="background-color: #efeff0"
    >
      <div class="flex-container">
        <div>
          <!-- ใส่เนื้อหาของ side navigation ที่นี่ -->
          <v-list-item style="background-color: #0c1d2f; padding: 8px">
            <div class="nav-header">
              <img
                src="@/assets/icons/sansiri-logo-sign.svg"
                style="padding-right: 12px"
              />
              <div class="nav-header-text">
                <span style="font-size: 16px; color: #ffffff; font-weight: bold"
                  >Upload
                </span>
                <span style="font-size: 16px; color: #ffffff"
                  >WorkShcedule</span
                >
              </div>
            </div>
          </v-list-item>
          <!-- User Info -->
          <v-list-item style="background-color: #d7d8d9; padding: 16px">
            <div class="user-info-container">
              <!-- User Details -->
              <div class="user-details" v-if="!rail" style="">
                <p
                  id="user-name"
                  style="margin: 0; font-size: 14px; font-weight: bold"
                >
                  {{ userName }}
                </p>
                <p
                  id="user-email"
                  style="margin: 0; font-size: 12px; color: #1b5faa"
                >
                  {{ userEmail }}
                </p>

                <div class="role-container">
                  <img src="@/assets/icons/ic_employee_circle_line.svg" />
                  <p
                    id="role-name"
                    style="font-size: 14px; font-weight: normal"
                  >
                    {{ userRole }}
                  </p>
                </div>
              </div>

              <!-- Toggle Icon -->
              <div class="icon-toggle">
                <img
                  src="@/assets/icons/toggle_nav.svg"
                  id="toggle-nav"
                  alt="Toggle Navigation"
                  @click.stop="toggleRail"
                  :class="{ 'rotate-icon': rail }"
                />
              </div>
            </div>
          </v-list-item>

          <v-divider style="margin-bottom: 16px"></v-divider>

          <v-list-item
            v-for="(item, index) in navItems"
            id="nav-list-item"
            :key="index"
            :class="{ 'selected-item': selectedItem === index }"
            @click="selectItem(index)"
            link
          >
            <router-link class="routerLink" :to="item.route">
              <div class="nav-list">
                <img :src="item.icon" width="20" height="20" />
                <v-list-item-title
                  v-if="!rail"
                  :class="[
                    'nav-list-text',
                    { 'nav-list-text-bold': selectedItem === index },
                  ]"
                >
                  {{ item.title }}
                </v-list-item-title>
              </div>
            </router-link>
          </v-list-item>
        </div>

        <div style="padding: 16px">
          <v-btn
            v-if="!rail"
            id="btn-logout"
            @click="handleLogout()"
            variant="outlined"
            style="
              font-size: 14px;
              font-weight: bold;
              margin-right: 16px;
              color: #1b5faa;
              width: 100%;
            "
          >
            <template v-slot:prepend>
              <img
                src="@/assets/icons/ic_logout.svg"
                alt="My Icon"
                width="24"
                height="24"
              />
            </template>
            ออกจากระบบ
          </v-btn>

          <v-btn
            v-else
            variant="text"
            id="btn-logout-rail"
            @click="handleLogout()"
            icon
            style="width: 48px; height: 48px; justify-content: center"
          >
            <img
              src="@/assets/icons/ic_logout.svg"
              alt="Logout Icon"
              width="24"
              height="24"
            />
          </v-btn>
        </div>
      </div>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import IconUpload from '@/assets/icons/ic_doc_attached.svg';
import IconReport from '@/assets/icons/ic_doc_report.svg';
import IconSettings from '@/assets/icons/ic_setting_line.svg';
import { logout } from '@/auth/authService';
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useEmployeeDetailStore } from '@/stores/EmployeeDetail';
import { EmployeeDetailPayload } from '@/models/EmployeeDetailPayload';
import { EmployeeDetailResponse } from '@/models/EmployeeDetailResponse';
import { useEmployeeRoleDetailStore } from '@/stores/EmployeeRole';
import config from '../../config';
const envConfig = config();
const menuIdUploadFileList = (envConfig.SelfHost.menuId as any).uploadFileList;
const menuIdReport = (envConfig.SelfHost.menuId as any).report;
const menuIdSetting = (envConfig.SelfHost.menuId as any).setting;
const employeeDetailStore = useEmployeeDetailStore();
const employeeRoleStore = useEmployeeRoleDetailStore();

const isLoading = computed(
  () => employeeDetailStore.isLoading || employeeRoleStore.isLoading,
);
const error = computed(
  () => employeeDetailStore.error || employeeRoleStore.error,
);
// State variables
const drawer = ref(true);
const rail = ref(false);
const selectedItem = ref<number | null>(null);
console.log('FIRST_LOADING...', isLoading.value);

const userRole = ref('');
const userName = ref(sessionStorage.getItem('userName'));
const userEmail = ref(sessionStorage.getItem('userEmail'));
const navItems = ref<{ title: string; icon: string; route: string | '' }[]>([]);
const route = useRouter();

// Methods
const selectItem: any = (index: number) => {
  selectedItem.value = index;
};

const toggleRail = () => {
  rail.value = !rail.value;
};

const handleLogout = async () => {
  await logout();
};

const getIconForMenu = (menuID: string) => {
  console.log('menuID::>', menuID);
  switch (menuID) {
    case menuIdUploadFileList:
      return IconUpload;
    case menuIdReport:
      return IconReport;
    case menuIdSetting:
      return IconSettings;
    default:
      return IconUpload;
  }
};

const getRouteForMenu = (menuID: string) => {
  switch (menuID) {
    case menuIdUploadFileList:
      return '/uploadList';
    case menuIdReport:
      return '/report';
    case menuIdSetting:
      return '/setting';
    default:
      return;
  }
};

const fetchDetails = async () => {
  // Create the payload
  const payload: EmployeeDetailPayload = {
    email: userEmail.value ? [userEmail.value] : [''],
    empWorkStatus: '',
    empName: '',
    empLastName: '',
    companyCode: '',
    divisionCode: null,
    departmentCode: null,
    empGroupCode: '',
    pageNumber: 1,
  };

  try {
    await employeeDetailStore.fetchEmployeeDetail(payload);
    const empDetail = employeeDetailStore.empDetail;
    console.log('empDetail::>', empDetail);
    const empCode = empDetail?.empCode || '';
    console.log('empCode::>', empCode);
    if (empCode) {
      await employeeRoleStore.fetchEmployeeRoleDetail(empCode);
      userRole.value = employeeRoleStore?.roleDetail?.roles[0]?.roleName || '';
      const menuData = employeeRoleStore.roleDetail?.menu || [];
      console.log('menuData::>', menuData);
      console.log('userRole::>', userRole.value);
      navItems.value = menuData.map((menu) => ({
        title: menu.menuName,
        icon: getIconForMenu(menu.menuID) || '',
        route: getRouteForMenu(menu.menuID) || '',
      }));
    } else {
      alert('คุณไม่มีสิทธิ์ในการเข้าถึงระบบ');
      handleLogout();
      console.log('Employee code is missing.');
    }
  } catch (error) {
    console.error('Error fetching details:', error);
  } finally {
    console.log('FINALLY_LOADING...', isLoading.value);
  }
};
onMounted(fetchDetails);
</script>

<style scoped>
.nav-header {
  display: flex;
  align-items: center;
}

.nav-header-text {
  border-left: 1px solid;
  color: #ffffff;
  padding-left: 12px;
}
.user-info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.user-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.icon-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}
.nav-list {
  display: flex;
  align-items: center;
  height: 40px;
}
.nav-list-text {
  font-size: 14px;
  margin-left: 8px;
  text-decoration: none !important;
  color: #4a4d51;
}

.nav-list-text-bold {
  font-size: 14px;
  margin-left: 8px;
  color: #000;
  font-weight: bold;
}
.selected-item {
  background-color: #ffffff;
}

.rotate-icon {
  transform: rotate(180deg);
  margin-right: 10px;
  transition: transform 0.5s ease;
}
.flex-container {
  display: flex; /* ใช้ flexbox */
  flex-direction: column; /* จัดเรียงเนื้อหาในแนวตั้ง */
  justify-content: space-between; /* ผลัก div1 ขึ้นบนและ div2 ลงล่าง */
  height: 100vh; /* ให้ div นี้กินความสูงเต็มหน้าจอ */
}

.drawer-container {
  width: 240px;
}

.drawer-container[rail] {
  width: 72px;
}

.role-container {
  display: flex;
  align-items: center;
  background-color: #c3d5d2;
  margin-top: 16px;
  padding: 2px 8px;
  border-radius: 4px;
  gap: 4px;
  font-size: 14px;
  font-weight: normal;
  color: #387469;
  border: 1px solid #387469;
  width: fit-content;
}
.routerLink {
  text-decoration: none !important; /* Remove underline */
}
</style>
