import { defineStore } from 'pinia';
import axios from 'axios';
import { EmployeeRoleDetailResponse } from '@/models/EmployeeRoleResponse';
import config from '../../config';

export const useEmployeeRoleDetailStore = defineStore('employeeRole', {
  state: () => ({
    roleDetail: null as EmployeeRoleDetailResponse | null,
    isLoading: true,
    error: null,
  }),
  actions: {
    async fetchEmployeeRoleDetail(payload: string) {
      this.isLoading = true;
      this.error = null;
      const accessToken = sessionStorage.getItem('accessToken');
      const envConfig = config();
      try {
        const response = await axios.get(
          `${(envConfig.UWSApi as any).host}/api/Authorize/${payload}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        console.log('response_role::>', response?.data?.data);
        this.roleDetail = response?.data?.data || null; // จัดเก็บข้อมูลที่ได้จาก API
      } catch (error) {
        error = error; // จัดการกับข้อผิดพลาด
      } finally {
        this.isLoading = false;
      }
    },
  },
});
