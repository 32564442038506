import { defineStore } from 'pinia';
import axios from 'axios';
import config from '../../config';
import { OrganizationPayload } from '@/models/ProjecOrganizationPayload';
import { OrganizationResponse } from '@/models/ProjecOrganizationResponse';

export const useProjecOrganizationStore = defineStore('ProjecOrganization', {
  state: () => ({
    porjectOrganization: null as OrganizationResponse | null,
    isLoading: true,
    error: '' as string,
  }),
  actions: {
    async fetchProjecOrganization(payload: OrganizationPayload) {
      console.log('HELLOCLEAN');
      this.isLoading = true;
      this.error = '';
      const envConfig = config();
      console.log('payload_porjectOrganization::>', payload);
      try {
        const response = await axios.post(
          `${(envConfig.EmployeeDetail as any).host}/Organization`,
          payload,
          {
            headers: {
              Authorization: (envConfig.EmployeeDetail as any).token as string,
            },
          },
        );
        console.log(
          'response_porjectOrganization::>',
          this.porjectOrganization,
        );
        this.porjectOrganization = response?.data?.respond_data || null;
      } catch (error) {
        console.log('error:::>', error);
        error = error;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
