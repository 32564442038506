import { defineStore } from 'pinia';
import axios from 'axios';
import { UploadFileResponse } from '@/models/UploadFileResponse';
import { UploadFilePayload } from '@/models/UploadFilePayload';
import config from '../../config';
import moment from 'moment';

export const useUpLoadFileStore = defineStore('uploadFile', {
  state: () => ({
    uploadFile: null as UploadFileResponse | null,
    isLoading: true,
    error: null,
  }),
  actions: {
    async fetchUpLoadFileDetail(payload: UploadFilePayload) {
      this.isLoading = true;
      this.error = null;
      const accessToken = sessionStorage.getItem('accessToken');
      const envConfig = config();
      try {
        const response = await axios.post(
          `${(envConfig.UWSApi as any).host}/api/UploadFile`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        console.log('response_uploadFile::>', response?.data);

        this.uploadFile = response?.data?.data || null;
      } catch (error) {
        error = error;
      } finally {
        this.isLoading = false;
      }
    },
  },
});

function formatCreateDate(dateString: string): string {
  return moment(dateString).format('DD / MM / YYYY HH:mm');
}
