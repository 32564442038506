<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'App',
  setup() {
    const route = useRoute();
    console.log('route::>', route.meta);
    const layout = computed(() => {
      return route.meta.layout || 'div'; // Default to 'div' if no layout is specified
    });

    console.log('layout::>', layout);

    return {
      layout,
    };
  },
});
</script>

<style>
html,
body,
#app {
  font-family: GraphikTH, Arial, sans-serif;
}
</style>
