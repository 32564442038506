import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify'; // นำเข้า Vuetify plugin
import { createPinia } from 'pinia';
import router from './router';
import { initializeMsal } from './auth/msalInstance';

//createApp(App).use(createPinia()).use(router).use(vuetify).mount('#app');

initializeMsal()
  .then(() => {
    const app = createApp(App);
    app.use(createPinia());
    app.use(router);
    app.use(vuetify);
    app.mount('#app');
  })
  .catch((error) => {
    console.error('Error initializing MSAL:', error);
  });
