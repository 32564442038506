import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icons/ic_upload.svg'
import _imports_1 from '@/assets/icons/xls_enable.svg'
import _imports_2 from '@/assets/icons/xls_disable.svg'
import _imports_3 from '@/assets/icons/ic_upload_success.svg'
import _imports_4 from '@/assets/icons/ic_upload_warning.svg'
import _imports_5 from '@/assets/icons/ic_upload_error.svg'


const _hoisted_1 = { class: "main-dialog-content" }
const _hoisted_2 = { class: "detail-contrainer" }
const _hoisted_3 = { class: "header-dialog" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "project-detail" }
const _hoisted_6 = {
  id: "period-detail",
  style: {"font-size":"16px","font-weight":"bold","margin-bottom":"16px"}
}
const _hoisted_7 = { style: {"color":"#1b5faa","font-weight":"normal"} }
const _hoisted_8 = { class: "add-file-contrainer" }
const _hoisted_9 = {
  key: 0,
  src: _imports_1,
  alt: "My Icon",
  width: "80",
  height: "80",
  style: {"margin-top":"16px"}
}
const _hoisted_10 = {
  key: 1,
  src: _imports_2,
  alt: "My Icon",
  width: "80",
  height: "80",
  style: {"margin-top":"16px"}
}
const _hoisted_11 = {
  key: 2,
  id: "file-name",
  style: {"font-size":"14px","margin-bottom":"8px","color":"#1b5faa"}
}
const _hoisted_12 = {
  key: 3,
  style: {"font-size":"14px","margin-bottom":"8px"}
}
const _hoisted_13 = { class: "btn-upload-contrainer" }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 2 }
const _hoisted_16 = { class: "btn-upload-warning-contrainer" }
const _hoisted_17 = { key: 3 }

import { ref, watch, computed, onMounted } from 'vue';
// Watch for changes in drawer state and emit it to the parent
import { useUpLoadSetUpStore } from '@/stores/UploadSetUp';
import { useProjecOrganizationStore } from '@/stores/ProjecOrganization';
import { useUpLoadFileStore } from '@/stores/UploadFile';
import { OrganizationPayload } from '@/models/ProjecOrganizationPayload';
import { UploadFileResponse } from '@/models/UploadFileResponse';
import { UploadFilePayload } from '@/models/UploadFilePayload';
import {
  OrganizationResponse,
  OrganizationDropdown,
} from '@/models/ProjecOrganizationResponse';
import config from '../../config';


export default /*@__PURE__*/_defineComponent({
  __name: 'UploadDialog',
  props: {
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    required: true,
  },
},
  setup(__props, { emit: __emit }) {

const envConfig = config();
const storeUploadSetUp = useUpLoadSetUpStore();
const storeProjecOrganization = useProjecOrganizationStore();
const storeUpLoadFile = useUpLoadFileStore();
const currentPeriodText = ref('');
const hasFetchedData = ref(false);
const stateUploadPage = ref('process');
const empCode = sessionStorage.getItem('empCode') || '';
const periodYear = ref<number | null>(null);
const periodMonth = ref<number | null>(null);
const fileInput = ref<HTMLInputElement | null>(null);
const fileDoc = ref<File | null>(null);
let selectedProject = ref<OrganizationDropdown | null>(null);
const uploadedFileName = ref<string>('');
const mappedOrganizations = ref<OrganizationDropdown[]>([]);
const monthMap: any = {
  มกราคม: 'jan',
  กุมภาพันธ์: 'feb',
  มีนาคม: 'mar',
  เมษายน: 'apr',
  พฤษภาคม: 'may',
  มิถุนายน: 'jun',
  กรกฎาคม: 'jul',
  สิงหาคม: 'aug',
  กันยายน: 'sep',
  ตุลาคม: 'oct',
  พฤศจิกายน: 'nov',
  ธันวาคม: 'dec',
};
const OrgLevel = (envConfig.EmployeeDetail as any).orgLevel;
const isLoading = computed(
  () => storeUploadSetUp.isLoading || storeProjecOrganization.isLoading,
);
const error = computed(
  () => storeUploadSetUp.error || storeProjecOrganization.error,
);
const payload: OrganizationPayload = {
  OrgCode: '',
  OrgLevel: OrgLevel,
  IsActive: true,
};
console.log('payload_organization::>', payload);

const uploadFile = async () => {
  if (!fileDoc.value) {
    console.warn('No file to upload');
    return;
  }
  const payloadUploadFile: UploadFilePayload = {
    projectCode: selectedProject?.value?.orgCode || '',
    fileMonth: periodMonth?.value || null,
    fileYear: periodYear?.value || null,
    createBy: empCode,
    file: fileDoc?.value || null,
  };
  console.log('payloadUploadFile::>', payloadUploadFile);
  try {
    await storeUpLoadFile.fetchUpLoadFileDetail(payloadUploadFile);
    if (storeUpLoadFile.uploadFile) {
      console.log('File uploaded successfully:', storeUpLoadFile.uploadFile);
    }
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};

const fetchDetails = async () => {
  console.log('hasFetchedData::>', hasFetchedData);
  if (hasFetchedData.value) return;
  try {
    await storeUploadSetUp.fetchUpLoadSetUpDetail();
    const setupUpload = storeUploadSetUp.periodDetail;
    console.log('setupUpload::>', setupUpload);
    currentPeriodText.value = setupUpload?.period?.currentPeriodText || '';
    periodYear.value = setupUpload?.period?.currentPeriodYear
      ? parseInt(setupUpload.period.currentPeriodYear, 10)
      : null;
    periodMonth.value = setupUpload?.period?.currentPeriodMonth
      ? parseInt(setupUpload.period.currentPeriodMonth, 10)
      : null;
    await storeProjecOrganization.fetchProjecOrganization(payload);
    console.log(
      'storeProjecOrganization?.porjectOrganization::>',
      storeProjecOrganization?.porjectOrganization,
    );
    if (Array.isArray(storeProjecOrganization?.porjectOrganization)) {
      mappedOrganizations.value =
        storeProjecOrganization.porjectOrganization.map((item) => ({
          orgCode: item.orgCode,
          orgNameTH: item.orgNameTH,
        }));
    } else {
      mappedOrganizations.value = [];
    }
    console.log('mappedOrganizations::>', mappedOrganizations.value);
  } catch (error) {
    console.error('Error fetching details:', error);
  } finally {
    console.log('fetchDetails_LOADING...', isLoading.value);
    hasFetchedData.value = true;
  }
};

const triggerFileInput = () => {
  fileInput.value?.click();
};

const handleFileUpload = (event: Event) => {
  const input = event.target as HTMLInputElement;
  const file = input.files?.[0];
  if (file) {
    const projectName = selectedProject?.value?.orgNameTH || '';
    const period = currentPeriodText?.value || '';
    if (!period) {
      alert('ระบบเกิดข้อผิดพลาด ไม่สามารถอัพโหลดไฟล์ได้');
      return;
    }

    if (!selectedProject.value) {
      alert('กรุณาเลือกโครงการก่อนอัปโหลดไฟล์');
      return;
    }
    const periodFileName = formatPeriod(period);
    uploadedFileName.value = `${projectName}_${periodFileName}`;
    fileDoc.value = file;
  }
};
onMounted(() => {
  if (!hasFetchedData.value) {
    console.log('::::::: onMounted_ORG :::::::');
    fetchDetails();
  }
});

const props = __props;

const emit = __emit;
const drawer = ref(props.modelValue);
const showDrawer = ref(props.modelValue);

const closeDrawer = () => {
  drawer.value = false;
};

function formatPeriod(periodText: string) {
  const [monthThai, year] = periodText.split(' ');
  const monthEnglish: string = monthMap[monthThai];
  return `${monthEnglish}_${year}`;
}

watch(selectedProject, (newValue) => {
  console.log('selectedProject updated:', newValue);
});
watch(
  () => props.modelValue,
  (newVal) => {
    drawer.value = newVal;
  },
);
watch(drawer, (newVal) => {
  emit('update:modelValue', newVal);
  showDrawer.value = newVal;
});

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (!isLoading.value)
    ? (_openBlock(), _createBlock(_component_v_navigation_drawer, {
        key: 0,
        modelValue: drawer.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((drawer).value = $event)),
        temporary: "",
        style: {
      margin: 0,
      padding: 0,
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      height: '100%',
      zIndex: 1010,
    }
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _cache[3] || (_cache[3] = _createElementVNode("img", {
                  src: _imports_0,
                  alt: "My Icon",
                  width: "24",
                  height: "24",
                  style: {"margin-top":"3px"}
                }, null, -1)),
                _cache[4] || (_cache[4] = _createElementVNode("p", { style: {"font-size":"20px","font-weight":"bold","line-height":"32px","margin-left":"8px"} }, " อัปโหลดตารางการทำงาน ", -1)),
                _createVNode(_component_v_btn, {
                  style: {"margin-top":"3px","margin-left":"120px"},
                  icon: "",
                  variant: "text",
                  onClick: closeDrawer
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { style: {"width":"24px","height":"24px","color":"#1b5faa"} }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("mdi-close")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_component_v_divider, {
                thickness: 1,
                class: "border-opacity-100",
                color: "#D7D8D9"
              }),
              (stateUploadPage.value == 'process')
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("p", _hoisted_6, [
                        _cache[5] || (_cache[5] = _createTextVNode(" ประจำรอบ : ")),
                        _createElementVNode("span", _hoisted_7, _toDisplayString(currentPeriodText.value), 1)
                      ]),
                      _cache[6] || (_cache[6] = _createElementVNode("p", { style: {"font-size":"14px","font-weight":"bold","margin-bottom":"8px"} }, [
                        _createTextVNode(" โครงการ "),
                        _createElementVNode("span", { style: {"color":"red"} }, "*")
                      ], -1)),
                      _createVNode(_component_v_select, {
                        id: "select-project",
                        clearable: "",
                        density: "compact",
                        items: mappedOrganizations.value,
                        modelValue: _unref(selectedProject),
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedProject) ? (selectedProject).value = $event : selectedProject = $event)),
                        variant: "outlined",
                        "item-title": "orgNameTH",
                        "return-object": "",
                        placeholder: "เลือกโครงการ",
                        style: {"width":"413px"}
                      }, null, 8, ["items", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", null, [
                        (uploadedFileName.value)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                          : (_openBlock(), _createElementBlock("img", _hoisted_10)),
                        (uploadedFileName.value)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_11, " อัปโหลดไฟล์: " + _toDisplayString(uploadedFileName.value), 1))
                          : (_openBlock(), _createElementBlock("p", _hoisted_12, " ลากไฟล์ที่ต้องการมาวาง หรือ ")),
                        _createVNode(_component_v_btn, {
                          id: "get-file",
                          variant: "outlined",
                          color: "#162E46",
                          style: {"margin-bottom":"16px"},
                          onClick: triggerFileInput
                        }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode(" เลือกไฟล์จากเครื่อง ")
                          ])),
                          _: 1
                        }),
                        _createElementVNode("input", {
                          id: "search-file",
                          type: "file",
                          ref_key: "fileInput",
                          ref: fileInput,
                          accept: ".xlsx",
                          style: {"display":"none"},
                          onChange: handleFileUpload
                        }, null, 544)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_v_btn, {
                        id: "btn-cancel",
                        variant: "outlined",
                        color: "#E33E50",
                        onClick: closeDrawer,
                        style: {"margin-right":"8px","width":"203px","height":"40px"}
                      }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode(" ยกเลิก")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_btn, {
                        id: "btn-upload-file-dialog",
                        disabled: !uploadedFileName.value,
                        style: _normalizeStyle([
                uploadedFileName.value
                  ? 'background-color: #1B5FAA;color: #FFFFFF;'
                  : 'background-color: #D7D8D9; opacity: 0.8; color: #A2A4A7;'
              , {"width":"203px","height":"40px","font-size":"14px"}]),
                        onClick: uploadFile
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode("อัปโหลด ")
                        ])),
                        _: 1
                      }, 8, ["disabled", "style"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (stateUploadPage.value == 'success')
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "success-upload-contrainer" }, [
                      _createElementVNode("img", {
                        src: _imports_3,
                        alt: "My Icon",
                        width: "120",
                        height: "120"
                      }),
                      _createElementVNode("div", null, [
                        _createElementVNode("p", { style: {"font-size":"20px","font-weight":"bold"} }, " อัปโหลดตารางการทำงาน "),
                        _createElementVNode("p", { style: {"font-size":"20px","font-weight":"bold"} }, "เสร็จสมบูรณ์ !")
                      ]),
                      _createElementVNode("div", { style: {"margin-top":"8px"} }, [
                        _createElementVNode("p", { style: {"font-size":"16px","color":"#4a4d51"} }, " ระบบทำการอัปโหลดข้อมูลตารางการทำงาน "),
                        _createElementVNode("p", { style: {"font-size":"16px","color":"#4a4d51"} }, "เรียบร้อยแล้ว")
                      ])
                    ], -1)),
                    _createElementVNode("div", null, [
                      _createVNode(_component_v_btn, {
                        id: "btn-confirm-success",
                        color: "#1B5FAA",
                        style: {"width":"410px","height":"40px","font-size":"14px","margin-left":"16px","margin-top":"140px"},
                        onClick: closeDrawer
                      }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("ตกลง ")
                        ])),
                        _: 1
                      })
                    ])
                  ]))
                : _createCommentVNode("", true),
              (stateUploadPage.value == 'warning')
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "warning-upload-contrainer" }, [
                      _createElementVNode("img", {
                        src: _imports_4,
                        alt: "My Icon",
                        width: "120",
                        height: "120"
                      }),
                      _createElementVNode("div", null, [
                        _createElementVNode("p", { style: {"font-size":"20px","font-weight":"bold"} }, " ยืนยันการอัปโหลดไฟล์ ")
                      ]),
                      _createElementVNode("div", { style: {"margin-top":"8px"} }, [
                        _createElementVNode("p", { style: {"font-size":"16px","color":"#4a4d51"} }, " โครงการนี้มีการอัปโหลดข้อมูลในระบบแล้ว "),
                        _createElementVNode("p", { style: {"font-size":"16px","color":"#4a4d51"} }, " คุณต้องการอัปโหลดไฟล์นี้หรือไม่ ")
                      ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_v_btn, {
                        id: "btn-back-warning",
                        variant: "outlined",
                        color: "#1B5FAA",
                        onClick: closeDrawer,
                        style: {"margin-right":"8px","width":"203px","height":"40px"}
                      }, {
                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                          _createTextVNode(" กลับหน้าก่อนหน้า")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_btn, {
                        id: "btn-confirm-warning",
                        color: "#1B5FAA",
                        style: {"width":"203px","height":"40px","font-size":"14px"}
                      }, {
                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                          _createTextVNode("ยืนยันอัปโหลด ")
                        ])),
                        _: 1
                      })
                    ])
                  ]))
                : _createCommentVNode("", true),
              (stateUploadPage.value == 'error')
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "error-upload-contrainer" }, [
                      _createElementVNode("img", {
                        src: _imports_5,
                        alt: "My Icon",
                        width: "120",
                        height: "120"
                      }),
                      _createElementVNode("div", null, [
                        _createElementVNode("p", { style: {"font-size":"20px","font-weight":"bold"} }, " ไม่สามารถอัปโหลดไฟล์ได้ "),
                        _createElementVNode("p", { style: {"font-size":"20px","font-weight":"bold"} }, " กรุณาตรวจสอบข้อมูลดังนี้ ")
                      ]),
                      _createElementVNode("div", { style: {"margin-top":"8px","margin-left":"32px"} }, [
                        _createElementVNode("ul", { style: {"font-size":"16px","color":"#4a4d51"} }, [
                          _createElementVNode("li", null, "รหัสพนักงานไม่ถูกต้อง"),
                          _createElementVNode("li", null, "ชื่อ Column ไม่ถูกต้อง"),
                          _createElementVNode("li", null, "ชื่อ Table ต้องใช้ชื่อว่า “Table”")
                        ])
                      ])
                    ], -1)),
                    _createElementVNode("div", null, [
                      _createVNode(_component_v_btn, {
                        id: "btn-back-error",
                        variant: "outlined",
                        color: "#1B5FAA",
                        onClick: closeDrawer,
                        style: {"width":"410px","height":"40px","font-size":"14px","margin-left":"16px","margin-top":"120px"}
                      }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createTextVNode(" กลับหน้าก่อนหน้า")
                        ])),
                        _: 1
                      })
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}
}

})