export const msalConfig = {
  auth: {
    clientId: 'c2201317-4d23-4332-9bad-6819c92ff097',
    authority:
      'https://login.microsoftonline.com/875bdd0a-688b-41d2-96b7-454d280043aa/oauth2/v2.0/authorize', // Fixed
    redirectUri: '/',
    postLogoutRedirectUri: '/login',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};
