import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

async function initializeMsal() {
  await msalInstance.initialize();
  console.log('MSAL instance initialized');
}

const checkSession = (email: string | null): boolean => {
  if (!email) return false;
  console.log('email::>', email);
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    console.log('User is authenticated', accounts);
    const targetUser = accounts.find((account) => account.username === email);
    if (targetUser) {
      console.log('User session is active for', targetUser.username);
      return true;
    } else {
      console.log('No active session for the specified user');
      return false;
    }
  } else {
    console.log('No active session found');
    return false;
  }
};

export { msalInstance, initializeMsal, checkSession };
