<template>
  <v-navigation-drawer
    v-model="drawer"
    v-if="!isLoading"
    temporary
    :style="{
      margin: 0,
      padding: 0,
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      height: '100%',
      zIndex: 1010,
    }"
  >
    <div class="main-dialog-content">
      <div class="detail-contrainer">
        <div class="header-dialog">
          <img
            src="@/assets/icons/ic_upload.svg"
            alt="My Icon"
            width="24"
            height="24"
            style="margin-top: 3px"
          />
          <p
            style="
              font-size: 20px;
              font-weight: bold;
              line-height: 32px;
              margin-left: 8px;
            "
          >
            อัปโหลดตารางการทำงาน
          </p>
          <v-btn
            style="margin-top: 3px; margin-left: 120px"
            icon
            variant="text"
            @click="closeDrawer"
          >
            <v-icon style="width: 24px; height: 24px; color: #1b5faa"
              >mdi-close</v-icon
            >
          </v-btn>
        </div>
        <v-divider
          :thickness="1"
          class="border-opacity-100"
          color="#D7D8D9"
        ></v-divider>

        <div v-if="stateUploadPage == 'process'">
          <div class="project-detail">
            <p
              id="period-detail"
              style="font-size: 16px; font-weight: bold; margin-bottom: 16px"
            >
              ประจำรอบ :
              <span style="color: #1b5faa; font-weight: normal">{{
                currentPeriodText
              }}</span>
            </p>
            <p style="font-size: 14px; font-weight: bold; margin-bottom: 8px">
              โครงการ <span style="color: red">*</span>
            </p>
            <v-select
              id="select-project"
              clearable
              density="compact"
              :items="mappedOrganizations"
              v-model="selectedProject"
              variant="outlined"
              item-title="orgNameTH"
              return-object
              placeholder="เลือกโครงการ"
              style="width: 413px"
            ></v-select>
          </div>
          <div class="add-file-contrainer">
            <div>
              <img
                v-if="uploadedFileName"
                src="@/assets/icons/xls_enable.svg"
                alt="My Icon"
                width="80"
                height="80"
                style="margin-top: 16px"
              />
              <img
                v-else="uploadedFileName"
                src="@/assets/icons/xls_disable.svg"
                alt="My Icon"
                width="80"
                height="80"
                style="margin-top: 16px"
              />
              <p
                id="file-name"
                style="font-size: 14px; margin-bottom: 8px; color: #1b5faa"
                v-if="uploadedFileName"
              >
                อัปโหลดไฟล์: {{ uploadedFileName }}
              </p>
              <p v-else style="font-size: 14px; margin-bottom: 8px">
                ลากไฟล์ที่ต้องการมาวาง หรือ
              </p>

              <v-btn
                id="get-file"
                variant="outlined"
                color="#162E46"
                style="margin-bottom: 16px"
                @click="triggerFileInput"
              >
                เลือกไฟล์จากเครื่อง
              </v-btn>
              <input
                id="search-file"
                type="file"
                ref="fileInput"
                accept=".xlsx"
                style="display: none"
                @change="handleFileUpload"
              />
            </div>
          </div>
          <div class="btn-upload-contrainer">
            <v-btn
              id="btn-cancel"
              variant="outlined"
              color="#E33E50"
              @click="closeDrawer"
              style="margin-right: 8px; width: 203px; height: 40px"
            >
              ยกเลิก</v-btn
            >
            <v-btn
              id="btn-upload-file-dialog"
              :disabled="!uploadedFileName"
              :style="
                uploadedFileName
                  ? 'background-color: #1B5FAA;color: #FFFFFF;'
                  : 'background-color: #D7D8D9; opacity: 0.8; color: #A2A4A7;'
              "
              @click="uploadFile"
              style="width: 203px; height: 40px; font-size: 14px"
              >อัปโหลด
            </v-btn>
          </div>
        </div>
        <div v-if="stateUploadPage == 'success'">
          <div class="success-upload-contrainer">
            <img
              src="@/assets/icons/ic_upload_success.svg"
              alt="My Icon"
              width="120"
              height="120"
            />
            <div>
              <p style="font-size: 20px; font-weight: bold">
                อัปโหลดตารางการทำงาน
              </p>
              <p style="font-size: 20px; font-weight: bold">เสร็จสมบูรณ์ !</p>
            </div>
            <div style="margin-top: 8px">
              <p style="font-size: 16px; color: #4a4d51">
                ระบบทำการอัปโหลดข้อมูลตารางการทำงาน
              </p>
              <p style="font-size: 16px; color: #4a4d51">เรียบร้อยแล้ว</p>
            </div>
          </div>
          <div>
            <v-btn
              id="btn-confirm-success"
              color="#1B5FAA"
              style="
                width: 410px;
                height: 40px;
                font-size: 14px;
                margin-left: 16px;
                margin-top: 140px;
              "
              @click="closeDrawer"
              >ตกลง
            </v-btn>
          </div>
        </div>
        <div v-if="stateUploadPage == 'warning'">
          <div class="warning-upload-contrainer">
            <img
              src="@/assets/icons/ic_upload_warning.svg"
              alt="My Icon"
              width="120"
              height="120"
            />
            <div>
              <p style="font-size: 20px; font-weight: bold">
                ยืนยันการอัปโหลดไฟล์
              </p>
            </div>
            <div style="margin-top: 8px">
              <p style="font-size: 16px; color: #4a4d51">
                โครงการนี้มีการอัปโหลดข้อมูลในระบบแล้ว
              </p>
              <p style="font-size: 16px; color: #4a4d51">
                คุณต้องการอัปโหลดไฟล์นี้หรือไม่
              </p>
            </div>
          </div>
          <div class="btn-upload-warning-contrainer">
            <v-btn
              id="btn-back-warning"
              variant="outlined"
              color="#1B5FAA"
              @click="closeDrawer"
              style="margin-right: 8px; width: 203px; height: 40px"
            >
              กลับหน้าก่อนหน้า</v-btn
            >
            <v-btn
              id="btn-confirm-warning"
              color="#1B5FAA"
              style="width: 203px; height: 40px; font-size: 14px"
              >ยืนยันอัปโหลด
            </v-btn>
          </div>
        </div>
        <div v-if="stateUploadPage == 'error'">
          <div class="error-upload-contrainer">
            <img
              src="@/assets/icons/ic_upload_error.svg"
              alt="My Icon"
              width="120"
              height="120"
            />
            <div>
              <p style="font-size: 20px; font-weight: bold">
                ไม่สามารถอัปโหลดไฟล์ได้
              </p>
              <p style="font-size: 20px; font-weight: bold">
                กรุณาตรวจสอบข้อมูลดังนี้
              </p>
            </div>
            <div style="margin-top: 8px; margin-left: 32px">
              <ul style="font-size: 16px; color: #4a4d51">
                <li>รหัสพนักงานไม่ถูกต้อง</li>
                <li>ชื่อ Column ไม่ถูกต้อง</li>
                <li>ชื่อ Table ต้องใช้ชื่อว่า “Table”</li>
              </ul>
            </div>
          </div>
          <div>
            <v-btn
              id="btn-back-error"
              variant="outlined"
              color="#1B5FAA"
              @click="closeDrawer"
              style="
                width: 410px;
                height: 40px;
                font-size: 14px;
                margin-left: 16px;
                margin-top: 120px;
              "
            >
              กลับหน้าก่อนหน้า</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>
<script setup lang="ts">
import { ref, defineProps, defineEmits, watch, computed, onMounted } from 'vue';
// Watch for changes in drawer state and emit it to the parent
import { useUpLoadSetUpStore } from '@/stores/UploadSetUp';
import { useProjecOrganizationStore } from '@/stores/ProjecOrganization';
import { useUpLoadFileStore } from '@/stores/UploadFile';
import { OrganizationPayload } from '@/models/ProjecOrganizationPayload';
import { UploadFileResponse } from '@/models/UploadFileResponse';
import { UploadFilePayload } from '@/models/UploadFilePayload';
import {
  OrganizationResponse,
  OrganizationDropdown,
} from '@/models/ProjecOrganizationResponse';
import config from '../../config';

const envConfig = config();
const storeUploadSetUp = useUpLoadSetUpStore();
const storeProjecOrganization = useProjecOrganizationStore();
const storeUpLoadFile = useUpLoadFileStore();
const currentPeriodText = ref('');
const hasFetchedData = ref(false);
const stateUploadPage = ref('process');
const empCode = sessionStorage.getItem('empCode') || '';
const periodYear = ref<number | null>(null);
const periodMonth = ref<number | null>(null);
const fileInput = ref<HTMLInputElement | null>(null);
const fileDoc = ref<File | null>(null);
let selectedProject = ref<OrganizationDropdown | null>(null);
const uploadedFileName = ref<string>('');
const mappedOrganizations = ref<OrganizationDropdown[]>([]);
const monthMap: any = {
  มกราคม: 'jan',
  กุมภาพันธ์: 'feb',
  มีนาคม: 'mar',
  เมษายน: 'apr',
  พฤษภาคม: 'may',
  มิถุนายน: 'jun',
  กรกฎาคม: 'jul',
  สิงหาคม: 'aug',
  กันยายน: 'sep',
  ตุลาคม: 'oct',
  พฤศจิกายน: 'nov',
  ธันวาคม: 'dec',
};
const OrgLevel = (envConfig.EmployeeDetail as any).orgLevel;
const isLoading = computed(
  () => storeUploadSetUp.isLoading || storeProjecOrganization.isLoading,
);
const error = computed(
  () => storeUploadSetUp.error || storeProjecOrganization.error,
);
const payload: OrganizationPayload = {
  OrgCode: '',
  OrgLevel: OrgLevel,
  IsActive: true,
};
console.log('payload_organization::>', payload);

const uploadFile = async () => {
  if (!fileDoc.value) {
    console.warn('No file to upload');
    return;
  }
  const payloadUploadFile: UploadFilePayload = {
    projectCode: selectedProject?.value?.orgCode || '',
    fileMonth: periodMonth?.value || null,
    fileYear: periodYear?.value || null,
    createBy: empCode,
    file: fileDoc?.value || null,
  };
  console.log('payloadUploadFile::>', payloadUploadFile);
  try {
    await storeUpLoadFile.fetchUpLoadFileDetail(payloadUploadFile);
    if (storeUpLoadFile.uploadFile) {
      console.log('File uploaded successfully:', storeUpLoadFile.uploadFile);
    }
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};

const fetchDetails = async () => {
  console.log('hasFetchedData::>', hasFetchedData);
  if (hasFetchedData.value) return;
  try {
    await storeUploadSetUp.fetchUpLoadSetUpDetail();
    const setupUpload = storeUploadSetUp.periodDetail;
    console.log('setupUpload::>', setupUpload);
    currentPeriodText.value = setupUpload?.period?.currentPeriodText || '';
    periodYear.value = setupUpload?.period?.currentPeriodYear
      ? parseInt(setupUpload.period.currentPeriodYear, 10)
      : null;
    periodMonth.value = setupUpload?.period?.currentPeriodMonth
      ? parseInt(setupUpload.period.currentPeriodMonth, 10)
      : null;
    await storeProjecOrganization.fetchProjecOrganization(payload);
    console.log(
      'storeProjecOrganization?.porjectOrganization::>',
      storeProjecOrganization?.porjectOrganization,
    );
    if (Array.isArray(storeProjecOrganization?.porjectOrganization)) {
      mappedOrganizations.value =
        storeProjecOrganization.porjectOrganization.map((item) => ({
          orgCode: item.orgCode,
          orgNameTH: item.orgNameTH,
        }));
    } else {
      mappedOrganizations.value = [];
    }
    console.log('mappedOrganizations::>', mappedOrganizations.value);
  } catch (error) {
    console.error('Error fetching details:', error);
  } finally {
    console.log('fetchDetails_LOADING...', isLoading.value);
    hasFetchedData.value = true;
  }
};

const triggerFileInput = () => {
  fileInput.value?.click();
};

const handleFileUpload = (event: Event) => {
  const input = event.target as HTMLInputElement;
  const file = input.files?.[0];
  if (file) {
    const projectName = selectedProject?.value?.orgNameTH || '';
    const period = currentPeriodText?.value || '';
    if (!period) {
      alert('ระบบเกิดข้อผิดพลาด ไม่สามารถอัพโหลดไฟล์ได้');
      return;
    }

    if (!selectedProject.value) {
      alert('กรุณาเลือกโครงการก่อนอัปโหลดไฟล์');
      return;
    }
    const periodFileName = formatPeriod(period);
    uploadedFileName.value = `${projectName}_${periodFileName}`;
    fileDoc.value = file;
  }
};
onMounted(() => {
  if (!hasFetchedData.value) {
    console.log('::::::: onMounted_ORG :::::::');
    fetchDetails();
  }
});

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    required: true,
  },
});

const emit = defineEmits();
const drawer = ref(props.modelValue);
const showDrawer = ref(props.modelValue);

const closeDrawer = () => {
  drawer.value = false;
};

function formatPeriod(periodText: string) {
  const [monthThai, year] = periodText.split(' ');
  const monthEnglish: string = monthMap[monthThai];
  return `${monthEnglish}_${year}`;
}

watch(selectedProject, (newValue) => {
  console.log('selectedProject updated:', newValue);
});
watch(
  () => props.modelValue,
  (newVal) => {
    drawer.value = newVal;
  },
);
watch(drawer, (newVal) => {
  emit('update:modelValue', newVal);
  showDrawer.value = newVal;
});
</script>

<style scoped>
.main-dialog-content {
  width: 700px;
  height: 100vh;
  max-width: 900px;
  background-color: #ffffff;
  overflow: auto;
}
.detail-contrainer {
  display: flex;
  flex-direction: column;
}

.header-dialog {
  display: flex;
  align-items: center;
  padding: 0px 16px;
}
.project-detail {
  padding: 16px;
}

.add-file-contrainer {
  border: 1px dashed #a2a4a7; /* ความหนา 2px, เส้นประ, สีฟ้า */
  padding: 16px; /* ระยะห่างภายในกล่อง */
  margin: 0 16px 16px;
  border-radius: 8px; /* มุมกล่องโค้งเล็กน้อย */
  width: 300px; /* ความกว้างของกล่อง */
  text-align: center; /* จัดข้อความให้อยู่กลาง */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 413px;
}
.btn-upload-contrainer {
  display: flex;
  margin: 140px 16px 16px;
}
.success-upload-contrainer {
  margin: 120px 32px 32px;
}
.warning-upload-contrainer {
  margin: 120px 32px 32px;
}
.error-upload-contrainer {
  margin: 120px 32px 32px;
}
.btn-upload-warning-contrainer {
  display: flex;
  margin: 200px 16px 16px;
}
</style>
