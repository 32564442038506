import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/Artwork.png'
import _imports_1 from '@/assets/icons/ic_xls_file.svg'
import _imports_2 from '@/assets/icons/ic_upload_white.svg'
import _imports_3 from '@/assets/icons/ic_history.svg'


const _hoisted_1 = { style: {"display":"flex","flex-direction":"column","min-height":"100vh"} }
const _hoisted_2 = { style: {"flex-grow":"1"} }
const _hoisted_3 = { class: "layout-main" }
const _hoisted_4 = { class: "upload-header-container" }
const _hoisted_5 = { class: "header-warning" }
const _hoisted_6 = { style: {"margin-left":"16px"} }
const _hoisted_7 = { class: "layout-main" }
const _hoisted_8 = { class: "upload-header-container" }
const _hoisted_9 = { style: {"font-size":"16px","font-weight":"bold"} }
const _hoisted_10 = {
  id: "total-upload-list-show",
  style: {"font-size":"16px","font-weight":"normal","color":"#1b5faa"}
}
const _hoisted_11 = { style: {"display":"flex","align-items":"center","justify-content":"center"} }
const _hoisted_12 = { class: "table-work-schedule" }
const _hoisted_13 = { style: {"text-align":"center"} }
const _hoisted_14 = {
  key: 0,
  src: _imports_3,
  alt: "My Icon",
  width: "20",
  height: "20"
}
const _hoisted_15 = { style: {"text-align":"center"} }
const _hoisted_16 = ["href"]
const _hoisted_17 = ["onClickOnce"]
const _hoisted_18 = { style: {"text-align":"center"} }
const _hoisted_19 = { style: {"text-align":"center"} }

import { ref, onMounted, computed, watch } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { th } from 'date-fns/locale';
import { useUpLoadListStore } from '@/stores/GetUploadList';
import { useUpLoadSetUpStore } from '@/stores/UploadSetUp';
import { UploadList } from '@/models/UploadListResopnse';
import UploadDialog from '@/components/UploadDialog.vue';
import moment from 'moment';


export default /*@__PURE__*/_defineComponent({
  __name: 'UploadFileList',
  setup(__props) {

const currentDate = new Date();
const date = ref({
  month: currentDate.getMonth(),
  year: currentDate.getFullYear(),
});

const thLocale: any = th;
const uploadListData = ref<UploadList[]>([]);
const searchProject = ref('');
const hasFetchedData = ref(false);
const uploadble = ref(false);

const overlay = ref(true);

const storeUploadList = useUpLoadListStore();
const storeUploadSetUp = useUpLoadSetUpStore();
const isLoadingFillter = ref(true);
const showUploadDialog = ref(false);

const filteredUploadList = computed(() => {
  const selectedDate = date.value;
  const selectedMonth = selectedDate.month + 1;
  const selectedYear = selectedDate.year;

  return uploadListData.value.filter((item) => {
    isLoadingFillter.value = true;
    const matchesProject =
      searchProject.value.trim() === '' ||
      item.projectName
        .toLowerCase()
        .includes(searchProject.value.toLowerCase());
    const matchesDate =
      item.fileMonth == selectedMonth && item.fileYear == selectedYear;
    isLoadingFillter.value = false;
    return matchesProject && matchesDate;
  });
});

const totalUploadList = computed(() => {
  console.log('length:::>', filteredUploadList?.value?.length || 0);
  return filteredUploadList?.value?.length || 0;
});
const isLoading = computed(
  () => storeUploadSetUp.isLoading || storeUploadList.isLoading,
);
const error = computed(() => storeUploadSetUp.error || storeUploadList.error);

const fetchDetails = async () => {
  if (hasFetchedData.value) return;
  try {
    await storeUploadSetUp.fetchUpLoadSetUpDetail();
    const setupUpload = storeUploadSetUp.periodDetail;
    uploadble.value = setupUpload?.period?.canUpload || false;
    const empCode = ref(sessionStorage.getItem('empCode') || '');
    await storeUploadList.fetchUpLoadListDetail(empCode.value);
    if (Array.isArray(storeUploadList?.uploadList)) {
      uploadListData.value = storeUploadList.uploadList;
    } else {
      uploadListData.value = [];
    }
  } catch (error) {
    console.error('Error fetching details:', error);
  } finally {
    console.log('fetchDetails_LOADING...', isLoading.value);
    hasFetchedData.value = true;
  }
};
onMounted(() => {
  if (!hasFetchedData.value) {
    console.log('::::::: onMounted :::::::', hasFetchedData.value);
    fetchDetails();
  }
});

//search
/*const filteredItems = computed(() => {
  if (!searchProject.value) return dataUpload.value;
  const query = searchProject.value.toLowerCase();

  return dataUpload.value.filter((item) => {
    // ตรวจสอบค่าทุกฟิลด์ที่ต้องการให้ค้นหา
    return (
      item.projectName.toLowerCase().includes(query) ||
      item.uploadedBy.toLowerCase().includes(query) ||
      item.uploadDate.includes(query)
    );
  });
}); */
// Data Table State

const options = ref({
  page: 1,
  itemsPerPage: 10,
  sortBy: ['projectName'],
  sortDesc: [false],
});

const headers = ref([
  {
    title: 'โครงการ',
    value: 'projectName',
    key: 'projectName',
    class: 'success--text title',
  },
  { title: 'เดือน', value: 'fileMonth' },
  { title: 'ปี', value: 'fileYear' },
  {
    title: 'วันที่อัพโหลด',
    value: 'createDate',
    key: 'createDate',
  },
  { title: 'ผู้อัปโหลด', value: 'createBy' },
  { title: '', value: 'isFileLasted' },
  { title: '', value: 'fileLink' },
]);

function handleDownload(fileLink: string) {
  // Open the file link in a new tab
  if (!fileLink) return;
  window.open(fileLink, '_blank');
}

const dataUpload = ref([
  {
    projectName: 'สารุญสิริ บางใหญ่',
    fileMonth: 10,
    fileYear: 2024,
    createDate: '11/11/2024 17:44',
    createBy: 'ไพฑูรย์ รุ่งฤดี',
    isFileLasted: true,
    fileLink:
      'https://sansirimail-my.sharepoint.com/:x:/r/personal/sirinporn_sansiri_com/_layouts/15/Doc.aspx?sourcedoc=%7Be17acbd5-c729-4e5d-b98a-c61e04e67b55%7D&action=edit&activeCell=%27API%27!F2&wdinitialsession=54e07c40-e58d-379a-d994-d163d1efd255&wdrldsc=2&wdrldc=1&wdrldr=AccessTokenExpiredWarningGatekeeperCookieMismatch%2C',
  },
  {
    projectName: 'สารุญสิริ บางนา',
    fileMonth: 10,
    fileYear: 2024,
    createDate: '10/11/2024 17:44',
    createBy: 'อนันต์เป็ด สนุกดี',
    isFileLasted: false,
    fileLink:
      'https://sansirimail-my.sharepoint.com/:x:/r/personal/sirinporn_sansiri_com/_layouts/15/Doc.aspx?sourcedoc=%7Be17acbd5-c729-4e5d-b98a-c61e04e67b55%7D&action=edit&activeCell=%27API%27!F2&wdinitialsession=54e07c40-e58d-379a-d994-d163d1efd255&wdrldsc=2&wdrldc=1&wdrldr=AccessTokenExpiredWarningGatekeeperCookieMismatch%2C',
  },
]);

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[13] || (_cache[13] = _createStaticVNode("<div style=\"display:flex;justify-content:space-between;\" data-v-3f0ff466><div class=\"layout-main\" data-v-3f0ff466><p style=\"font-size:28px;font-weight:bold;\" data-v-3f0ff466> ยินดีต้อนรับสู่ระบบ Upload Work Schedule </p><p style=\"font-size:16px;font-weight:normal;\" data-v-3f0ff466> ระบบอัปโหลดตารางการทำงาน </p></div><div data-v-3f0ff466><img src=\"" + _imports_0 + "\" alt=\"My Icon\" style=\"\" data-v-3f0ff466></div></div><div style=\"border-bottom:1px solid;color:#c0c0c0;\" data-v-3f0ff466></div>", 2)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[8] || (_cache[8] = _createElementVNode("p", { style: {"font-size":"20px","font-weight":"bold"} }, " รายการอัปโหลดตารางการทำงาน ", -1)),
          _createElementVNode("div", null, [
            _createVNode(_component_v_btn, {
              variant: "outlined",
              id: "btn-download-template",
              href: "/template/UploadFile_Clean.xlsx",
              download: "",
              style: {"font-size":"14px","font-weight":"bold","margin-right":"16px"}
            }, {
              prepend: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: "My Icon",
                  width: "24",
                  height: "24"
                }, null, -1)
              ])),
              default: _withCtx(() => [
                _cache[5] || (_cache[5] = _createTextVNode(" ดาวน์โหลดเทมเพลต "))
              ]),
              _: 1
            }),
            _createVNode(_component_v_btn, {
              variant: "tonal",
              id: "btn-upload-file",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (showUploadDialog.value = true)),
              style: {"font-size":"14px","font-weight":"bold","background-color":"#1b5faa","color":"#ffffff"},
              disabled: !uploadble.value
            }, {
              prepend: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("img", {
                  src: _imports_2,
                  alt: "My Icon",
                  width: "24",
                  height: "24"
                }, null, -1)
              ])),
              default: _withCtx(() => [
                _cache[7] || (_cache[7] = _createTextVNode(" อัปโหลด "))
              ]),
              _: 1
            }, 8, ["disabled"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_v_icon, {
            icon: "mdi-alert-circle",
            color: "#08327F"
          })
        ]),
        _cache[9] || (_cache[9] = _createElementVNode("div", { style: {"margin-left":"8px"} }, [
          _createElementVNode("p", { style: {"font-size":"14px"} }, " อัปโหลดไฟล์ได้ตั้งแต่วันที่ 1 - 15 ของทุกเดือน ")
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_9, [
              _cache[10] || (_cache[10] = _createTextVNode(" จำนวนรายการ: ")),
              _createElementVNode("span", _hoisted_10, _toDisplayString(totalUploadList.value), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_v_text_field, {
              modelValue: searchProject.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchProject).value = $event)),
              id: "search-project",
              label: "ค้นหาจากชื่อโครงการ",
              "prepend-inner-icon": "mdi-magnify",
              variant: "outlined",
              density: "compact",
              style: {"margin-right":"16px","width":"360px","height":"40px"}
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(Datepicker), {
              id: "search-period",
              modelValue: date.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((date).value = $event)),
              clearable: false,
              "format-locale": _unref(thLocale),
              language: "th",
              format: "MMMM yyyy",
              "month-picker": "",
              style: {"width":"250px"}
            }, null, 8, ["modelValue", "format-locale"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        (isLoading.value || isLoadingFillter.value)
          ? (_openBlock(), _createBlock(_component_v_data_table, {
              key: 0,
              id: "table-upload-list",
              loading: isLoading.value || isLoadingFillter.value,
              class: "custom-table",
              "items-per-page": options.value.itemsPerPage,
              "server-items-length": totalUploadList.value,
              options: options.value
            }, null, 8, ["loading", "items-per-page", "server-items-length", "options"]))
          : _createCommentVNode("", true),
        (!isLoading.value && !isLoadingFillter.value && totalUploadList.value > 0)
          ? (_openBlock(), _createBlock(_component_v_data_table, {
              key: 1,
              id: "table-upload-list",
              headers: headers.value,
              items: filteredUploadList.value,
              class: "custom-table",
              loading: isLoading.value || isLoadingFillter.value,
              "items-per-page": options.value.itemsPerPage,
              "server-items-length": totalUploadList.value,
              options: options.value
            }, {
              "item.isFileLasted": _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_13, [
                  (!item.isFileLasted)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_14))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "top"
                  }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode("document already sent")
                    ])),
                    _: 1
                  })
                ])
              ]),
              "item.fileLink": _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("a", {
                    href: item.fileLink,
                    target: "_blank"
                  }, [
                    _createElementVNode("img", {
                      src: _imports_1,
                      alt: "File Icon",
                      width: "20",
                      height: "20",
                      onClickOnce: ($event: any) => (handleDownload(item.fileLink))
                    }, null, 40, _hoisted_17),
                    _createVNode(_component_v_tooltip, {
                      activator: "parent",
                      location: "top"
                    }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode("download")
                      ])),
                      _: 1
                    })
                  ], 8, _hoisted_16)
                ])
              ]),
              "item.fileMonth": _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_18, _toDisplayString(item.fileMonth), 1)
              ]),
              "item.fileYear": _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_19, _toDisplayString(item.fileYear), 1)
              ]),
              _: 1
            }, 8, ["headers", "items", "loading", "items-per-page", "server-items-length", "options"]))
          : _createCommentVNode("", true),
        (!isLoading.value && !isLoadingFillter.value && totalUploadList.value <= 0)
          ? (_openBlock(), _createBlock(_component_v_data_table, {
              key: 2,
              id: "table-upload-list-emtry",
              items: filteredUploadList.value,
              class: "custom-table",
              loading: isLoading.value || isLoadingFillter.value,
              "items-per-page": options.value.itemsPerPage,
              "server-items-length": totalUploadList.value,
              options: options.value
            }, null, 8, ["items", "loading", "items-per-page", "server-items-length", "options"]))
          : _createCommentVNode("", true)
      ]),
      (showUploadDialog.value)
        ? (_openBlock(), _createBlock(UploadDialog, {
            key: 0,
            id: "upload-dialog",
            style: {"display":"flex","flex-direction":"row-reverse"},
            modelValue: showUploadDialog.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((showUploadDialog).value = $event))
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "footer" }, " UploadWorkShcedule System V 1.0 | Copyright © 2024 Sansiri PLC. ", -1))
  ]))
}
}

})