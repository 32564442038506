import { defineStore } from 'pinia';
import axios from 'axios';
import { EmployeeDetailPayload } from '@/models/EmployeeDetailPayload';
import config from '../../config';
import { EmployeeDetailResponse } from '@/models/EmployeeDetailResponse';
export const useEmployeeDetailStore = defineStore('employeeDetail', {
  state: () => ({
    empDetail: null as EmployeeDetailResponse | null,
    isLoading: true,
    error: '' as string,
  }),
  actions: {
    async fetchEmployeeDetail(payload: EmployeeDetailPayload) {
      console.log('HELLO');
      this.isLoading = true;
      this.error = '';
      const envConfig = config();
      try {
        const response = await axios.post(
          `${(envConfig.EmployeeDetail as any).host}/EmployeeDetail/GetEmpDetail`,
          payload,
          {
            headers: {
              Authorization: (envConfig.EmployeeDetail as any).token as string,
            },
          },
        );
        console.log(
          'response::>',
          response?.data?.respond_data[0]?.empDetail[0] || null,
        );

        this.empDetail = response?.data?.respond_data[0]?.empDetail[0] || null;
        console.log('response_empDetail::>', this.empDetail);
      } catch (error) {
        error = error;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
