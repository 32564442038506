<template v-if="!isLoading">
  <div style="display: flex; flex-direction: column; min-height: 100vh">
    <div style="flex-grow: 1">
      <div style="display: flex; justify-content: space-between">
        <div class="layout-main">
          <p style="font-size: 28px; font-weight: bold">
            ยินดีต้อนรับสู่ระบบ Upload Work Schedule
          </p>
          <p style="font-size: 16px; font-weight: normal">
            ระบบอัปโหลดตารางการทำงาน
          </p>
        </div>
        <div>
          <img src="@/assets/images/Artwork.png" alt="My Icon" style="" />
        </div>
      </div>

      <div style="border-bottom: 1px solid; color: #c0c0c0"></div>

      <div class="layout-main">
        <div class="upload-header-container">
          <p style="font-size: 20px; font-weight: bold">
            รายการอัปโหลดตารางการทำงาน
          </p>

          <div>
            <v-btn
              variant="outlined"
              id="btn-download-template"
              href="/template/UploadFile_Clean.xlsx"
              download
              style="font-size: 14px; font-weight: bold; margin-right: 16px"
            >
              <template v-slot:prepend>
                <img
                  src="@/assets/icons/ic_xls_file.svg"
                  alt="My Icon"
                  width="24"
                  height="24"
                />
              </template>
              ดาวน์โหลดเทมเพลต
            </v-btn>

            <v-btn
              variant="tonal"
              id="btn-upload-file"
              @click="showUploadDialog = true"
              style="
                font-size: 14px;
                font-weight: bold;
                background-color: #1b5faa;
                color: #ffffff;
              "
              :disabled="!uploadble"
            >
              <template v-slot:prepend>
                <img
                  src="@/assets/icons/ic_upload_white.svg"
                  alt="My Icon"
                  width="24"
                  height="24"
                />
              </template>
              อัปโหลด
            </v-btn>
          </div>
        </div>
      </div>

      <div class="header-warning">
        <div style="margin-left: 16px">
          <v-icon icon="mdi-alert-circle" color="#08327F" />
        </div>
        <div style="margin-left: 8px">
          <p style="font-size: 14px">
            อัปโหลดไฟล์ได้ตั้งแต่วันที่ 1 - 15 ของทุกเดือน
          </p>
        </div>
      </div>

      <div class="layout-main">
        <div class="upload-header-container">
          <div>
            <p style="font-size: 16px; font-weight: bold">
              จำนวนรายการ:
              <span
                id="total-upload-list-show"
                style="font-size: 16px; font-weight: normal; color: #1b5faa"
                >{{ totalUploadList }}</span
              >
            </p>
          </div>
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <v-text-field
              v-model="searchProject"
              id="search-project"
              label="ค้นหาจากชื่อโครงการ"
              prepend-inner-icon="mdi-magnify"
              variant="outlined"
              density="compact"
              style="margin-right: 16px; width: 360px; height: 40px"
            ></v-text-field>
            <Datepicker
              id="search-period"
              v-model="date"
              :clearable="false"
              :format-locale="thLocale"
              language="th"
              format="MMMM yyyy"
              month-picker
              style="width: 250px"
            />
          </div>
        </div>
      </div>

      <!--<div v-if="!isLoading">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div> -->

      <div class="table-work-schedule">
        <!-- รอโหลดข้อมูล -->
        <v-data-table
          id="table-upload-list"
          v-if="isLoading || isLoadingFillter"
          :loading="isLoading || isLoadingFillter"
          class="custom-table"
          :items-per-page="options.itemsPerPage"
          :server-items-length="totalUploadList"
          :options.sync="options"
        ></v-data-table>

        <v-data-table
          v-if="!isLoading && !isLoadingFillter && totalUploadList > 0"
          id="table-upload-list"
          :headers="headers"
          :items="filteredUploadList"
          class="custom-table"
          :loading="isLoading || isLoadingFillter"
          :items-per-page="options.itemsPerPage"
          :server-items-length="totalUploadList"
          :options.sync="options"
        >
          <template v-slot:item.isFileLasted="{ item }">
            <div style="text-align: center">
              <img
                src="@/assets/icons/ic_history.svg"
                alt="My Icon"
                width="20"
                height="20"
                v-if="!item.isFileLasted"
              />
              <v-tooltip activator="parent" location="top"
                >document already sent</v-tooltip
              >
            </div>
          </template>

          <template v-slot:item.fileLink="{ item }">
            <div style="text-align: center">
              <a :href="item.fileLink" target="_blank">
                <img
                  src="@/assets/icons/ic_xls_file.svg"
                  alt="File Icon"
                  width="20"
                  height="20"
                  @click.once="handleDownload(item.fileLink)"
                />
                <v-tooltip activator="parent" location="top"
                  >download</v-tooltip
                >
              </a>
            </div>
          </template>
          <template v-slot:item.fileMonth="{ item }">
            <div style="text-align: center">
              {{ item.fileMonth }}
            </div>
          </template>

          <template v-slot:item.fileYear="{ item }">
            <div style="text-align: center">
              {{ item.fileYear }}
            </div>
          </template>
        </v-data-table>

        <v-data-table
          v-if="!isLoading && !isLoadingFillter && totalUploadList <= 0"
          id="table-upload-list-emtry"
          :items="filteredUploadList"
          class="custom-table"
          :loading="isLoading || isLoadingFillter"
          :items-per-page="options.itemsPerPage"
          :server-items-length="totalUploadList"
          :options.sync="options"
        >
        </v-data-table>
      </div>
      <UploadDialog
        id="upload-dialog"
        style="display: flex; flex-direction: row-reverse"
        v-model="showUploadDialog"
        v-if="showUploadDialog"
      />
    </div>
    <div class="footer">
      UploadWorkShcedule System V 1.0 | Copyright © 2024 Sansiri PLC.
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { th } from 'date-fns/locale';
import { useUpLoadListStore } from '@/stores/GetUploadList';
import { useUpLoadSetUpStore } from '@/stores/UploadSetUp';
import { UploadList } from '@/models/UploadListResopnse';
import UploadDialog from '@/components/UploadDialog.vue';
import moment from 'moment';

const currentDate = new Date();
const date = ref({
  month: currentDate.getMonth(),
  year: currentDate.getFullYear(),
});

const thLocale: any = th;
const uploadListData = ref<UploadList[]>([]);
const searchProject = ref('');
const hasFetchedData = ref(false);
const uploadble = ref(false);

const overlay = ref(true);

const storeUploadList = useUpLoadListStore();
const storeUploadSetUp = useUpLoadSetUpStore();
const isLoadingFillter = ref(true);
const showUploadDialog = ref(false);

const filteredUploadList = computed(() => {
  const selectedDate = date.value;
  const selectedMonth = selectedDate.month + 1;
  const selectedYear = selectedDate.year;

  return uploadListData.value.filter((item) => {
    isLoadingFillter.value = true;
    const matchesProject =
      searchProject.value.trim() === '' ||
      item.projectName
        .toLowerCase()
        .includes(searchProject.value.toLowerCase());
    const matchesDate =
      item.fileMonth == selectedMonth && item.fileYear == selectedYear;
    isLoadingFillter.value = false;
    return matchesProject && matchesDate;
  });
});

const totalUploadList = computed(() => {
  console.log('length:::>', filteredUploadList?.value?.length || 0);
  return filteredUploadList?.value?.length || 0;
});
const isLoading = computed(
  () => storeUploadSetUp.isLoading || storeUploadList.isLoading,
);
const error = computed(() => storeUploadSetUp.error || storeUploadList.error);

const fetchDetails = async () => {
  if (hasFetchedData.value) return;
  try {
    await storeUploadSetUp.fetchUpLoadSetUpDetail();
    const setupUpload = storeUploadSetUp.periodDetail;
    uploadble.value = setupUpload?.period?.canUpload || false;
    const empCode = ref(sessionStorage.getItem('empCode') || '');
    await storeUploadList.fetchUpLoadListDetail(empCode.value);
    if (Array.isArray(storeUploadList?.uploadList)) {
      uploadListData.value = storeUploadList.uploadList;
    } else {
      uploadListData.value = [];
    }
  } catch (error) {
    console.error('Error fetching details:', error);
  } finally {
    console.log('fetchDetails_LOADING...', isLoading.value);
    hasFetchedData.value = true;
  }
};
onMounted(() => {
  if (!hasFetchedData.value) {
    console.log('::::::: onMounted :::::::', hasFetchedData.value);
    fetchDetails();
  }
});

//search
/*const filteredItems = computed(() => {
  if (!searchProject.value) return dataUpload.value;
  const query = searchProject.value.toLowerCase();

  return dataUpload.value.filter((item) => {
    // ตรวจสอบค่าทุกฟิลด์ที่ต้องการให้ค้นหา
    return (
      item.projectName.toLowerCase().includes(query) ||
      item.uploadedBy.toLowerCase().includes(query) ||
      item.uploadDate.includes(query)
    );
  });
}); */
// Data Table State

const options = ref({
  page: 1,
  itemsPerPage: 10,
  sortBy: ['projectName'],
  sortDesc: [false],
});

const headers = ref([
  {
    title: 'โครงการ',
    value: 'projectName',
    key: 'projectName',
    class: 'success--text title',
  },
  { title: 'เดือน', value: 'fileMonth' },
  { title: 'ปี', value: 'fileYear' },
  {
    title: 'วันที่อัพโหลด',
    value: 'createDate',
    key: 'createDate',
  },
  { title: 'ผู้อัปโหลด', value: 'createBy' },
  { title: '', value: 'isFileLasted' },
  { title: '', value: 'fileLink' },
]);

function handleDownload(fileLink: string) {
  // Open the file link in a new tab
  if (!fileLink) return;
  window.open(fileLink, '_blank');
}

const dataUpload = ref([
  {
    projectName: 'สารุญสิริ บางใหญ่',
    fileMonth: 10,
    fileYear: 2024,
    createDate: '11/11/2024 17:44',
    createBy: 'ไพฑูรย์ รุ่งฤดี',
    isFileLasted: true,
    fileLink:
      'https://sansirimail-my.sharepoint.com/:x:/r/personal/sirinporn_sansiri_com/_layouts/15/Doc.aspx?sourcedoc=%7Be17acbd5-c729-4e5d-b98a-c61e04e67b55%7D&action=edit&activeCell=%27API%27!F2&wdinitialsession=54e07c40-e58d-379a-d994-d163d1efd255&wdrldsc=2&wdrldc=1&wdrldr=AccessTokenExpiredWarningGatekeeperCookieMismatch%2C',
  },
  {
    projectName: 'สารุญสิริ บางนา',
    fileMonth: 10,
    fileYear: 2024,
    createDate: '10/11/2024 17:44',
    createBy: 'อนันต์เป็ด สนุกดี',
    isFileLasted: false,
    fileLink:
      'https://sansirimail-my.sharepoint.com/:x:/r/personal/sirinporn_sansiri_com/_layouts/15/Doc.aspx?sourcedoc=%7Be17acbd5-c729-4e5d-b98a-c61e04e67b55%7D&action=edit&activeCell=%27API%27!F2&wdinitialsession=54e07c40-e58d-379a-d994-d163d1efd255&wdrldsc=2&wdrldc=1&wdrldr=AccessTokenExpiredWarningGatekeeperCookieMismatch%2C',
  },
]);
</script>
<style scoped>
.layout-main {
  padding: 20px;
}
.upload-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-warning {
  border-left: 2px #08327f solid;
  background-color: #e4ecf5;
  margin-left: 20px;
  margin-right: 20px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.custom-datepicker {
  width: 250px;
}

.table-work-schedule {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 16px;
}
.v-data-table >>> th {
  font-size: 13px !important;
  font-weight: bold !important;
  background-color: #162e46 !important;
  height: 40px !important;
  color: #ffffff;
}

.dp__input_wrap >>> input {
  height: 45px !important;
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center */
  align-items: center; /* Horizontally center */
  height: 200px; /* You can adjust the height as needed */
  text-align: center; /* Optional: centers the text within the p tag */
}

.v-progress-circular {
  margin: 1rem;
}
.footer {
  font-size: 12px;
  color: #4a4d51;
  margin-left: 16px;
  margin-bottom: 10px;
}
::v-deep(.dp__input_wrap .dp__input) {
  height: 40px;
}
</style>
