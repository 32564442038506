export default () => ({
  port: parseInt(process.env.PORT, 10) || 8080,
  EmployeeDetail: {
    host: process.env.EMPLOYEE_HOST || 'https://employeeapi-stg.sansiri.net',
    orgLevel: '700',
    token:
      process.env.EMPLOYEE_TOKEN ||
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6IkVtcGxveWVlQVBULXN0ZyIsInJvbGUiOiJDdXN0b21fQUxMIiwibmJmIjoxNzE1MjQ5NTg4LCJleHAiOjEwMjA1Njc1MzU4OCwiaWF0IjoxNzE1MjQ5NTg4fQ.rUY_luCPhI4jjwQLTbsWTeagPQZYNykMlKXxAxY-VCE',
  },
  SelfHost: {
    host: process.env.SELF_HOST || '',
    menuId: {
      uploadFileList:
        process.env.SELF_HOST || '56733708-67fe-4dee-bfbd-4d916a3bf4b3',
      report: process.env.SELF_HOST || '067d1b02-899c-4add-bec5-b7c77cfec68c',
      setting: process.env.SELF_HOST || '5ec6c4f0-2968-4d18-ad88-43199cae54b3',
    },
  },

  UWSApi: {
    host: process.env.UWS_API_HOST || 'https://uws-api-stg.sansiri.net', ///'https://localhost:44314',
  },
});
