import { defineStore } from 'pinia';
import axios from 'axios';
import { UploadList } from '@/models/UploadListResopnse';
import config from '../../config';
import moment from 'moment';

export const useUpLoadListStore = defineStore('uploadList', {
  state: () => ({
    uploadList: null as UploadList[] | null,
    isLoading: true,
    error: null,
  }),
  actions: {
    async fetchUpLoadListDetail(payload: string) {
      this.isLoading = true;
      this.error = null;
      const accessToken = sessionStorage.getItem('accessToken');
      const envConfig = config();
      try {
        const response = await axios.get(
          `${(envConfig.UWSApi as any).host}/api/UploadFile/${payload}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        console.log('response_uploadList::>', response?.data?.data);
        this.uploadList = response?.data?.data || null;
        if (this.uploadList && Array.isArray(this.uploadList)) {
          this.uploadList = this.uploadList.map((item) => ({
            ...item,
            createDate: formatCreateDate(item.createDate),
          }));
        } else {
          this.uploadList = null;
        }
      } catch (error) {
        error = error; // จัดการกับข้อผิดพลาด
      } finally {
        this.isLoading = false;
      }
    },
  },
});

function formatCreateDate(dateString: string): string {
  return moment(dateString).format('DD / MM / YYYY HH:mm');
}
